import axios from "@/axios.js";

export default {
  addItem({ commit }, user) {
    debugger;
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("/API/Authentication/Registration?UserLanguageID="+1, user)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_Security",
            Object.assign(user, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};