<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <vs-input
      data-vv-validate-on="blur"
      label-placeholder="Name"
      name="displayName"
      placeholder="اسم المستخدم"
      v-model="displayName"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("displayName") }}</span>

    <vs-input

 data-vv-validate-on="blur"
 name="phone"
 type="text"
 label-placeholder="Phone Number"
 placeholder="Phone Number"
 v-model="phone"
 class="w-full mt-6"
/>
<span class="text-danger text-sm">{{ errors.first("phone") }}</span>


    <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
 
      name="password"
      label-placeholder="Password"
      placeholder=""
      v-model="password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <!-- <vs-input
      type="password"
      v-validate="'min:6|max:10|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirm_password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{
      errors.first("confirm_password")
    }}</span> -->

   
    <vs-button
      class="float-right mt-6"
      @click="registerUserJWt"
      
      >Register</vs-button
    >
  </div>
</template>

<script>
  import { domain } from "@/gloabelConstant.js";
  import moduleAuth from "@/store/settings/AddASecurity/moduleAuth.js";
  
export default {

  data() {
    return {
      baseURL: domain,
      displayName: "",
      phone: "",
      password: "",
      confirm_password: "",
      isTermsConditionAccepted: true
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.displayName != "" &&
        this.phone != "" &&
        this.password != "" &&
        this.confirm_password != "" &&
        this.isTermsConditionAccepted === true
      );
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: this.$t("LoginAttempt"),
          text: this.$t("Youarealreadyloggedin"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      // if (!this.validateForm || !this.checkLogin()) return;
      var UserLanguageID =  1;
      const payload = {
       
        userDetails: {        
          fullNameAr: this.displayName,
          phoneNumber: this.phone,
          password: this.password,
          userType: "SecurityOfficer",
          isApproved: true,
        }
      };
      this.$store.dispatch("moduleAuthList/addItem",payload , UserLanguageID);
    }
  },
  created() {
      if (!moduleAuth.isRegistered) {
        this.$store.registerModule("moduleAuthList", moduleAuth);
        moduleAuth.isRegistered = true;
      }
    },
    mounted() {
      this.isMounted = true;
    },
};
</script>
